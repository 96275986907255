export const New = () => {
  return (
    <div className="new">
      <p className="new__title">Какие новости?</p>
      <p className="new__description">Уменьшили размер приложения</p>
      <p className="new__description">
        Исправлена ошибка с push-уведомлениями, которая возникала у некоторых
        пользователей Android 10.
      </p>
      <p className="new__description">
        Добавлено месторасположение ближайшего Тинькофф Отделения, Банкомата или
        Терминала на карте.
      </p>
      <p className="new__description">
        Исправлена проблема, из-за которой текст в настройках был очень мелким
        для пользователей Android.
      </p>
      <p className="new__description">
        Теперь демосчета будут недоступны — вы сможете совершать сделки только
        на реальных брокерских счетах Тинькофф Инвест
      </p>
      {/* <p className="new__description">
        Исправлена проблема, из-за которой текст в настройках был очень мелким
        для пользователей Android.
      </p> */}
    </div>
  );
};
